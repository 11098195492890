import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from '../constants/routes';

export const dashboardAutocompleteAPI = createApi({
  reducerPath: 'dashboardAutocompleteAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    'Stages',
    'Types',
    'BrandGroups',
    'Brands',
    'Territories',
    'Locations',
    'MaterialGroups',
    'Materials'
  ],
  endpoints: (build) => ({
    fetchStages: build.query<any, any>({
      query: () => ({
        url: API_ROUTES.STAGE
      }),
      providesTags: (result) => [{ type: 'Stages', id: 'LIST' }]
    }),
    fetchTypes: build.query<any, any>({
      query: () => ({
        url: API_ROUTES.TYPE
      }),
      providesTags: (result) => [{ type: 'Types', id: 'LIST' }]
    }),
    fetchBrandGroups: build.query<any, any>({
      query: () => ({
        url: API_ROUTES.BRANDS.GROUP
      }),
      providesTags: (result) => [{ type: 'BrandGroups', id: 'LIST' }]
    }),
    fetchBrands: build.query<any, any>({
      query: (name: string) => ({
        url: `${API_ROUTES.BRANDS.ROOT}/${name}`
      }),
      providesTags: (result) => [{ type: 'Brands', id: 'LIST' }]
    }),
    fetchTerritories: build.query<any, any>({
      query: () => ({
        url: API_ROUTES.TERRITORIES.ROOT
      }),
      providesTags: (result) => [{ type: 'Territories', id: 'LIST' }]
    }),
    fetchLocations: build.query<any, any>({
      query: (params) => ({
        url: API_ROUTES.TERRITORIES.LOCATIONS,
        params
      }),
      providesTags: (result) => [{ type: 'Locations', id: 'LIST' }]
    }),
    fetchMaterialGroups: build.query<any, any>({
      query: () => ({
        url: API_ROUTES.MATERIALS.GROUPS
      }),
      providesTags: (result) => [{ type: 'MaterialGroups', id: 'LIST' }]
    }),
    fetchMaterials: build.query<any, any>({
      query: (ids) => ({
        url: API_ROUTES.MATERIALS.LISTS,
        params: {
          ids: ids
        }
      }),
      providesTags: (result) => [{ type: 'Materials', id: 'LIST' }]
    })
  })
});
export const {
  useFetchStagesQuery,
  useFetchTypesQuery,
  useFetchBrandGroupsQuery,
  useFetchBrandsQuery,
  useFetchTerritoriesQuery,
  useFetchLocationsQuery,
  useFetchMaterialGroupsQuery,
  useFetchMaterialsQuery
} = dashboardAutocompleteAPI;
