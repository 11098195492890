import * as React from 'react';
import styled from 'styled-components/macro';
import { Link, useParams } from 'react-router-dom';
import { spacing } from '@mui/system';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Box,
  Link as MuiLink,
  Container
} from '@mui/material';
import { PATH_DASHBOARD, PATH_USERS } from '../../../constants';
import { useFetchUserQuery } from '../../../services/UsersManagementService';
import { UserForm } from '../../../components/usersList/form/UserForm';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const EditUser: React.FC = () => {
  const { id = '' } = useParams();
  const { data, isLoading } = useFetchUserQuery({ id }, { refetchOnMountOrArgChange: true });
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 5 }}>
        <Typography variant="h3" gutterBottom>
          Edit User
        </Typography>
        <Breadcrumbs>
          <MuiLink component={Link} to={PATH_DASHBOARD.ROOT}>
            Dashboard admin
          </MuiLink>
          <MuiLink component={Link} to={PATH_USERS.LIST}>
            Users list
          </MuiLink>
          <Typography>Edit user</Typography>
        </Breadcrumbs>
        <UserForm data={data} isLoading={isLoading} />
      </Box>
    </Container>
  );
};

export default EditUser;
