import React, { useCallback } from 'react';
import { TextField } from '@mui/material';

type NumericInputProps = {
  label: string;
  min: number;
  value: string;
  setFieldValue: (name: string, value: string | number) => void;
  name: string;
  error: boolean;
  helperText: string | false | undefined;
};

const ONLY_NUMBERS_REGEX = /^[1-9][0-9]*$|^$/;
const DETECT_START_ZERO_REGEX = /^0+/;
const SYMBOLS = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Tab', '0'];

export const NumericInput: React.FC<NumericInputProps> = ({
  label,
  min,
  value,
  setFieldValue,
  name,
  error,
  helperText
}): JSX.Element => {
  const onChangeNumberField = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, fieldName: string) => {
      const value = event.target.value;
      setFieldValue(fieldName, value.replace(DETECT_START_ZERO_REGEX, ''));
    },
    [setFieldValue]
  );
  const handleKeydown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    const value = event.key;
    // check on numbers for Safari browser
    if (!ONLY_NUMBERS_REGEX.test(value) && !SYMBOLS.includes(value)) {
      event.preventDefault();
    }
  }, []);

  return (
    <TextField
      fullWidth
      label={label}
      type="number"
      InputProps={{ inputProps: { min } }}
      value={value}
      onChange={(event) => onChangeNumberField(event, name)}
      onKeyDown={(event) => handleKeydown(event)}
      error={error}
      helperText={helperText}
    />
  );
};
