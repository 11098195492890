import React from 'react';
import styled from 'styled-components/macro';

import { Paper, Typography } from '@mui/material';

import ResetPasswordComponent from '../../components/auth/ResetPassword';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const ResetPassword: React.FC = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Please enter a new password
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
};

export default ResetPassword;
