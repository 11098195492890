import * as React from 'react';
import { Box, Breadcrumbs, Container, Link as MuiLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD, PATH_USERS } from '../../../constants';
import { InviteUserForm } from '../../../components/usersList/inviteUserForm/InviteUserForm';

export const InviteUser: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 5 }}>
        <Typography variant="h3" gutterBottom>
          Invite User
        </Typography>
        <Breadcrumbs>
          <MuiLink component={Link} to={PATH_DASHBOARD.ROOT}>
            Dashboard admin
          </MuiLink>
          <MuiLink component={Link} to={PATH_USERS.LIST}>
            Users list
          </MuiLink>
          <Typography>Invite user</Typography>
        </Breadcrumbs>
        <InviteUserForm />
      </Box>
    </Container>
  );
};
