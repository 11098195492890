import * as Yup from 'yup';

export const rules = Yup.object().shape({
  company: Yup.object({
    id: Yup.number().required('Company is required'),
    name: Yup.string().required('Company is required')
  })
    .required('Company is required')
    .nullable(),
  brandGroup: Yup.string().required('Brand group is required'),
  brand: Yup.string().required('Brand is required'),
  version: Yup.string().required('Version is required'),
  stage: Yup.object({
    id: Yup.number().required('Stage of production is required'),
    name: Yup.string().required('Stage of production is required')
  })
    .required('Stage of production is required')
    .nullable(),
  type: Yup.object({
    id: Yup.number().required('Type is required'),
    name: Yup.string().required('Type is required')
  })
    .required('Type is required')
    .nullable(),
  SKU: Yup.string()
    .required('SKU is required')
    .max(64, 'The maximum length of the SKU is 64 characters'),
  tbc: Yup.boolean(),
  launchDate: Yup.date().when('tbc', {
    is: true,
    then: Yup.date().nullable().notRequired(),
    otherwise: Yup.date().required('Please choose launch date or TBC').typeError('Date is invalid')
  }),
  designOrigin: Yup.string().required('Design origin is required'),
  sustainabilityOptimisation: Yup.string().required('Sustainability is required'),
  componentCount: Yup.number()
    .required('Component count is required')
    .min(1, 'Component count is required'),
  materialCount: Yup.number()
    .required('Material count is required')
    .min(1, 'Material count is required'),
  materialGroups: Yup.array().min(1, 'Material groups are required'),
  materialLists: Yup.array().min(1, 'Material lists are required'),
  estimatedProductQuantity: Yup.number()
    .required('Estimated product quantity is required')
    .min(1, 'Estimated product quantity is required'),
  productionLocations: Yup.array().min(1, 'Location is required'),
  distributionTerritories: Yup.array().min(1, 'Distribution territories are required'),
  packedDimensions: Yup.string().required('Packed dimension is required'),
  materialStrategy: Yup.string()
    .required('Material strategy is required')
    .max(250, 'Material strategy can contain only 250 characters'),
  materialStrategyScore: Yup.number()
    .required('Material strategy score is required')
    .min(1, 'Material strategy score minimum is 1')
    .max(100, 'Material strategy score maximum is 100'),
  productConstruct: Yup.string()
    .required('Product construct is required')
    .max(250, 'Product construct can contain only 250 characters'),
  productConstructScore: Yup.number()
    .required('Product construct score is required')
    .min(1, 'Product construct score minimum is 1')
    .max(100, 'Product construct score maximum is 100'),
  recylability: Yup.string()
    .required('Recyclability is required')
    .max(250, 'Recyclability can contain only 250 characters'),
  recylabilityScore: Yup.number()
    .required('Recyclability score is required')
    .min(1, 'Recyclability score minimum is 1')
    .max(100, 'Recyclability score maximum is 100'),
  lifetime: Yup.string()
    .required('Lifetime is required')
    .max(250, 'Lifetime can contain only 250 characters'),
  lifetimeScore: Yup.number()
    .required('Lifetime score is required')
    .min(1, 'Lifetime score minimum is 1')
    .max(100, 'Lifetime score maximum is 100'),
  logistics: Yup.string()
    .required('Logistics is required')
    .max(250, 'Logistics can contain only 250 characters'),
  logisticsScore: Yup.number()
    .required('Logistics score is required')
    .min(1, 'Logistics score minimum is 1')
    .max(100, 'Logistics score maximum is 100'),
  notes: Yup.string()
    .required('Notes are required')
    .max(500, 'Notes can contain only 500 characters'),
  sustainabilityScore: Yup.number()
    .required('Sustainability score is required')
    .min(1, 'Sustainability score minimum is 1')
    .max(100, 'Sustainability score can contain only 100 characters')
});

export const recommendationsRules = Yup.object().shape({
  recommendations: Yup.array(
    Yup.object({
      title: Yup.string().required('Title is required'),
      text: Yup.string()
        .required('Text is required')
        .max(250, 'Text can contain only 250 characters'),
      score: Yup.number().required('Score is required')
    })
  )
});
