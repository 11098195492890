import { Navigate, useRoutes } from 'react-router-dom';

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import PresentationLayout from './layouts/Presentation';
import DashboardLayout from './layouts/Dashboard';
// Guards
import AuthGuard from './components/guards/AuthGuard';
import GuestGuard from './components/guards/GuestGuard';
// Auth components
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import Page404 from './pages/auth/Page404';
import Page500 from './pages/auth/Page500';
import ConfirmUpdateEmail from './pages/auth/ConfirmUpdateEmail';

import DecycleDashboardPage from './pages/decycleDashboard/DecycleDashboardPage';
import CreateBrandDashboard from './pages/decycleDashboard/create/CreateBrandDashboard';
import { DashboardsListPage } from './pages/decycleDashboard/DashboardListPage';
import EditBrandDashboard from './pages/decycleDashboard/edit/EditBrandDashboard';
import { UsersManagementListPage } from './pages/userManagement/UsersManagementListPage';
import EditUser from './pages/userManagement/edit/EditUser';
import SendCode from './pages/auth/SendCode';
import { ConfirmCode } from './pages/auth/ConfirmCode';
import { InviteUser } from './pages/userManagement/inviteUser/InviteUser';
import InviteRegister from './pages/auth/InviteRegister';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';

function Router() {
  return useRoutes([
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'sign-in',
          element: <SignIn />
        },
        {
          path: 'sign-up',
          element: <SignUp />
        },
        {
          path: 'register',
          element: <InviteRegister />
        },
        {
          path: 'reset-password',
          element: <ResetPassword />
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />
        },
        {
          path: 'confirm-email',
          element: <ConfirmUpdateEmail />
        },
        {
          path: 'send-2fa',
          element: <SendCode />
        },
        {
          path: 'send-2fa/code',
          element: <ConfirmCode />
        },
        {
          path: 'update-email',
          element: <ConfirmUpdateEmail />
        }
      ]
    },
    {
      path: '*',
      element: <AuthLayout />,
      children: [
        {
          path: '404',
          element: <Page404 />
        },
        {
          path: '500',
          element: <Page500 />
        },
        {
          path: '*',
          element: <Navigate to="/404" />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <PresentationLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <DecycleDashboardPage /> },
        { path: 'dashboard/list', element: <DashboardsListPage /> },
        { path: 'users/list', element: <UsersManagementListPage /> }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'dashboard', element: <></> },
        { path: 'dashboard/add', element: <CreateBrandDashboard /> },
        { path: 'dashboard/:id/edit', element: <EditBrandDashboard /> },
        { path: 'users/invite', element: <InviteUser /> },
        { path: 'users/:id/edit', element: <EditUser /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

export default Router;
