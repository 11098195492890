import * as Yup from 'yup';
import { ROLES } from '../../../constants/defaultValues';

export const rules = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Invalid email').max(255),
  role: Yup.string()
    .required('Role is required')
    .oneOf(ROLES, 'Invalid role')
    .typeError('Please, select role from list')
});
