import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, Button, Card, Modal, Typography } from '@mui/material';
import Cropper from 'react-cropper';
import styled from 'styled-components/macro';
import 'cropperjs/dist/cropper.css';

const Input = styled('input')({
  display: 'none'
});

const RootStyle = styled('div')(({ theme }) => ({
  maxWidth: 316,
  height: 198,
  margin: 'auto',
  borderRadius: '6px',
  padding: theme.spacing(0.5),
  border: `1px dashed rgba(145, 158, 171, 0.32)`
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '6px',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9
    }
  }
});

type PictureCardProps = {
  pictureUrl: string | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getCropData: () => void;
  setCropper: Dispatch<SetStateAction<Cropper>>;
};

const MAX_FILE_SIZE = 10;

export const PictureCard: React.FC<PictureCardProps> = ({
  pictureUrl = '',
  handleChange,
  getCropData,
  setCropper
}) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <Card sx={{ py: 3, px: 3, minWidth: '80%', paddingRight: '12px' }}>
      <Box>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary', mb: '12px' }}>
          Brand picture
        </Typography>
        <RootStyle>
          <DropZoneStyle>
            {pictureUrl && (
              <Box
                component="img"
                src={pictureUrl}
                sx={{
                  zIndex: 8,
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%,-50%)',
                  maxWidth: '100%',
                  maxHeight: '100%'
                }}
              />
            )}
          </DropZoneStyle>
        </RootStyle>
        <Box paddingTop="10px" textAlign="center">
          <Typography
            variant="caption"
            sx={{
              mb: 2,
              mx: 'auto',
              display: 'block',
              textAlign: 'center',
              color: 'text.secondary'
            }}
          >
            Maximum upload file size: {MAX_FILE_SIZE}MB.
            <br /> Allowed file types: .gif, .jpg, .jpeg, .png.
          </Typography>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleChange}
              onClick={(event) => {
                (event.target as HTMLInputElement).value = '';
              }}
            />
            <Button variant="contained" component="span">
              Upload
            </Button>
          </label>
          <Button
            sx={{ marginLeft: '10px' }}
            variant="contained"
            disabled={!pictureUrl}
            onClick={() => setIsOpenModal(true)}
            component="span"
          >
            Change
          </Button>
          <Modal
            open={isOpenModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                background: 'white',
                maxWidth: '600px',
                width: '90%',
                height: '500px',
                position: 'absolute' as 'absolute',
                borderRadius: '6px',
                padding: '20px',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <Cropper
                zoomTo={0.5}
                initialAspectRatio={1}
                src={pictureUrl || ''}
                viewMode={0}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={true}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                style={{ width: '100%', height: '400px' }}
                guides={true}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button
                  sx={{ marginRight: '20px' }}
                  variant="outlined"
                  onClick={() => closeModal()}
                  component="span"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    getCropData();
                    setIsOpenModal(false);
                  }}
                  component="span"
                >
                  Crop & Save
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Card>
  );
};
