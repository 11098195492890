import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from '../constants/routes';

type DashboardDataInfo = {
  company: string;
  brandGroup: string;
  brand: string;
  launchDate: string;
  designOrigin: string;
  sustainability: string;
  componentCount: number;
  materialCount: number;
  materialList: string;
  quantity: number;
  productionLocations: string;
  distributionTerritories: string;
  packedDimensions: number;
  materialStrategy: string;
  productConstruct: string;
  recyclability: string;
  lifetime: string;
  logistics: string;
  notes: string;
};
export type DashboardInfo = {
  dashboard: DashboardDataInfo;
};

export const dashboardAPI = createApi({
  reducerPath: 'dashboardAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['Dashboards'],
  endpoints: (build) => ({
    fetchDashboards: build.query<any, any>({
      query: (params) => ({
        url: API_ROUTES.DASHBOARD.ROOT,
        params
      }),
      providesTags: (result) => ['Dashboards']
    }),
    createDashboard: build.mutation<any, any>({
      query: (dashboard: any) => ({
        url: API_ROUTES.DASHBOARD.ROOT,
        method: 'POST',
        body: dashboard
      }),
      invalidatesTags: ['Dashboards']
    }),
    updateDashboard: build.mutation<any, any>({
      query: (dashboard: any) => ({
        url: API_ROUTES.DASHBOARD.ROOT,
        method: 'PUT',
        body: dashboard
      }),
      invalidatesTags: ['Dashboards']
    }),
    updateImage: build.mutation<any, any>({
      query: (body) => ({
        url: API_ROUTES.IMAGE,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Dashboards']
    }),
    fetchDashboard: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${API_ROUTES.DASHBOARD.ROOT}/${id}`
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => ['Dashboards']
    }),
    deleteDashboard: build.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `${API_ROUTES.DASHBOARD.DELETE}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Dashboards']
    }),
    createRecommendation: build.mutation<any, any>({
      query: (recommendation: any) => ({
        url: API_ROUTES.RECOMMENDATION,
        method: 'POST',
        body: recommendation
      }),
      invalidatesTags: ['Dashboards']
    }),
    updateRecommendation: build.mutation<any, any>({
      query: (body) => ({
        url: API_ROUTES.RECOMMENDATION,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['Dashboards']
    }),
    deleteRecommendation: build.mutation<any, number>({
      query: (id: number) => ({
        url: `${API_ROUTES.RECOMMENDATION}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Dashboards']
    })
  })
});
export const {
  useFetchDashboardsQuery,
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
  useUpdateImageMutation,
  useFetchDashboardQuery,
  useDeleteDashboardMutation,
  useCreateRecommendationMutation,
  useUpdateRecommendationMutation,
  useDeleteRecommendationMutation
} = dashboardAPI;
