import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from '../constants/routes';

type UserValues = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

type InviteUserProps = {
  email: string;
  role: string;
};

export const usersManagementAPI = createApi({
  reducerPath: 'usersManagementAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['Users'],
  endpoints: (build) => ({
    fetchUsers: build.query<any, any>({
      query: (params) => ({
        url: API_ROUTES.USERS.ROOT,
        params
      }),
      providesTags: (result) => ['Users']
    }),
    fetchUser: build.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `${API_ROUTES.USERS.ROOT}/${id}`
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => ['Users']
    }),
    inviteUser: build.mutation<{ message: string }, InviteUserProps>({
      query: (user: InviteUserProps) => ({
        url: API_ROUTES.USERS.INVITE,
        method: 'POST',
        body: user
      }),
      invalidatesTags: ['Users']
    }),
    updateUser: build.mutation<any, any>({
      query: (user: UserValues) => ({
        url: API_ROUTES.USERS.ROOT,
        method: 'PUT',
        body: user
      }),
      invalidatesTags: ['Users']
    }),
    deleteUser: build.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `${API_ROUTES.USERS.ROOT}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users']
    })
  })
});
export const {
  useFetchUsersQuery,
  useFetchUserQuery,
  useInviteUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation
} = usersManagementAPI;
