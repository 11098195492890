import * as React from 'react';
import styled from 'styled-components/macro';
import { Link, useParams } from 'react-router-dom';
import { spacing } from '@mui/system';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Box,
  Link as MuiLink,
  Container
} from '@mui/material';
import { PATH_DASHBOARD } from '../../../constants';
import { DashboardForm } from '../../../components/dashboardList/form/DashboardForm';
import { dashboardAPI, useFetchDashboardQuery } from '../../../services/DashboardsService';
import { useCallback } from 'react';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const EditBrandDashboard: React.FC = () => {
  const { id = '' } = useParams();

  const { data, isLoading } = useFetchDashboardQuery({ id }, { refetchOnMountOrArgChange: true });

  const [
    updateDashboard,
    { isSuccess: isSuccessUpdate, error: updateError, isLoading: isLoadingUpdate }
  ] = dashboardAPI.useUpdateDashboardMutation();

  const handleRequestUpdate = useCallback(
    (values: any) => {
      updateDashboard(values);
    },
    [updateDashboard]
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 5 }}>
        <Typography variant="h3" gutterBottom>
          Edit Brand Dashboard
        </Typography>
        <Breadcrumbs>
          <MuiLink component={Link} to={PATH_DASHBOARD.ROOT}>
            Dashboard admin
          </MuiLink>
          <MuiLink component={Link} to={PATH_DASHBOARD.LIST}>
            List
          </MuiLink>
          <Typography>Edit dashboard</Typography>
        </Breadcrumbs>
        <DashboardForm
          dashboardId={id}
          data={data}
          isLoading={isLoading}
          handleRequest={handleRequestUpdate}
          isSuccessRequest={isSuccessUpdate}
          error={updateError}
          isLoadingRequest={isLoadingUpdate}
          route={PATH_DASHBOARD.LIST}
        />
      </Box>
    </Container>
  );
};

export default EditBrandDashboard;
