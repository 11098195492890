import React from 'react';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD, PATH_USERS } from '../../constants';
import { Container, Typography, Button, Box, Grid, Link as MuiLink } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { UserRoles } from '../../constants/defaultValues';
import { StateWrapper } from '../../components/wrappers/StateWrapper';

const DecycleDashboardMain: React.FC = () => {
  const { user, isInitialized } = useAuth();
  return (
    <StateWrapper isLoading={!isInitialized}>
      <Container maxWidth="lg">
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          Welcome to the Impact Dashboard Admin Console
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
          Please select one of the following...
        </Typography>
        <Box sx={{ mt: 5 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10} sm={6}>
              <MuiLink component={Link} to={PATH_DASHBOARD.ADD} underline={'none'}>
                <Button fullWidth size="large" type="button" variant="contained">
                  Add new dashboard
                </Button>
              </MuiLink>
            </Grid>
            <Grid item xs={10} sm={6}>
              <MuiLink component={Link} to={PATH_DASHBOARD.LIST} underline={'none'}>
                <Button fullWidth size="large" type="button" variant="contained">
                  Edit existing dashboard
                </Button>
              </MuiLink>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10} sm={6} sx={{ mt: '8px' }}>
              <Button
                fullWidth
                size="large"
                type="button"
                onClick={() => window.open(PATH_DASHBOARD.VIEW_DASHBOARD, '_blank')?.focus()}
                variant="contained"
              >
                View dashboard
              </Button>
            </Grid>
            {(user?.role === UserRoles.SUPERADMIN || user?.role === UserRoles.ADMIN) && (
              <Grid item xs={10} sm={6} sx={{ mt: '8px' }}>
                <MuiLink component={Link} to={PATH_USERS.LIST} underline={'none'}>
                  <Button fullWidth size="large" type="button" variant="contained">
                    User management
                  </Button>
                </MuiLink>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </StateWrapper>
  );
};
export default DecycleDashboardMain;
