import React, { SyntheticEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Autocomplete, Box, Button, Card, Grid, Stack, TextField } from '@mui/material';
import { ROLES } from '../../../constants/defaultValues';
import { useFormik } from 'formik';
import { rules } from './rules';
import { useInviteUserMutation } from '../../../services/UsersManagementService';
import { PATH_USERS } from '../../../constants';

type InviteUserValues = {
  email: string;
  role: string;
};

export const InviteUserForm: React.FC = () => {
  const [inviteUser, { isSuccess }] = useInviteUserMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } =
    useFormik<InviteUserValues>({
      enableReinitialize: true,
      validateOnChange: true,
      initialValues: {
        email: '',
        role: ''
      },
      validationSchema: rules,
      onSubmit: async (values, { setSubmitting }) => {
        try {
          const { message } = await inviteUser(values).unwrap();
          enqueueSnackbar(message, {
            variant: 'success'
          });
          setSubmitting(false);
        } catch (err: any) {
          console.error('Invite user: ', err);
          enqueueSnackbar(err.data.message, {
            variant: 'error'
          });
        }
      }
    });

  useEffect(() => {
    if (isSuccess) {
      navigate(PATH_USERS.LIST);
    }
  }, [isSuccess, navigate]);

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={3} paddingTop="20px">
        <Grid item xs={12} sm={12} md={8} sx={{ padding: 4 }}>
          <Card sx={{ p: 3, bgcolor: 'background.paper' }}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <Autocomplete
                  autoComplete={false}
                  freeSolo
                  fullWidth
                  options={ROLES}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={values.role}
                  getOptionLabel={(option: any): string => option}
                  onChange={(event: SyntheticEvent, newValue) => {
                    setFieldValue('role', newValue);
                  }}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Role"
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                    />
                  )}
                />
              </Stack>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  value="save"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
