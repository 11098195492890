import React from 'react';
import { InputAdornment, TextField } from '@mui/material';

type CountingTextFieldProps = {
  label: string;
  minRows: number;
  maxRows: number;
  limit: number;
  error: boolean;
  helperText: string | false | undefined;
  currentLength: number;
};

export const CountingTextField: React.FC<CountingTextFieldProps> = ({
  label,
  minRows,
  maxRows,
  limit,
  currentLength,
  error,
  helperText,
  ...other
}) => (
  <TextField
    fullWidth
    multiline
    label={label}
    minRows={minRows}
    maxRows={maxRows}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <p>{`${currentLength}/${limit}`}</p>
        </InputAdornment>
      ),
      style: { alignItems: 'flex-end' }
    }}
    error={error}
    helperText={helperText}
    {...other}
  />
);
