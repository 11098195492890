import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, TextField as MuiTextField, Typography, Stack } from '@mui/material';
import { spacing } from '@mui/system';
import useAuth from '../../hooks/useAuth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import FormHelperText from '@mui/material/FormHelperText';

type VerifiedProps = {
  handleNext: any;
};
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Verified: React.FC<VerifiedProps> = ({ handleNext }) => {
  const { verified, checkRegistrationCode } = useAuth();
  const { search } = useLocation();
  const registrationCode = new URLSearchParams(search).get('code') || '';

  useLayoutEffect(() => {
    checkRegistrationCode(registrationCode);
  }, [checkRegistrationCode, registrationCode]);

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    touched,
    values
  } = useFormik({
    initialValues: {
      email: '',
      phoneNumber: '',
      submit: false
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Must be a valid email').max(255),
      phoneNumber: Yup.string().min(11, 'Phone number is not valid')
    }),
    validateOnChange: true,
    onSubmit: async (values) => {
      await verified(values.email, values.phoneNumber, registrationCode, handleNext);
    }
  });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Stack spacing={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <TextField
          type="email"
          name="email"
          label="Email Address"
          value={values.email}
          error={Boolean(touched.email && errors.email)}
          fullWidth
          helperText={touched.email && errors.email}
          onBlur={handleBlur}
          onChange={handleChange}
          sx={{ marginTop: '8px' }}
        />
        <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          or
        </Typography>
        <PhoneInput
          country="us"
          value={values.phoneNumber}
          onChange={(phone) => setFieldValue('phoneNumber', phone)}
          enableSearch={true}
          inputStyle={{
            width: '100%',
            borderColor: touched.phoneNumber && errors.phoneNumber ? 'red' : ''
          }}
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <FormHelperText style={{ color: '#d32f2f', marginTop: '3px', marginLeft: '14px' }}>
            {errors.phoneNumber}
          </FormHelperText>
        )}
        <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
          Send Code
        </Button>
      </Stack>
    </form>
  );
};

export default Verified;
