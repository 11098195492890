import React from 'react';
import styled from 'styled-components/macro';
import DecycleDashboardMain from './DecycleDashboardMain';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center'
}));

const DecycleDashboardPage: React.FC = () => {
  return (
    <RootStyle>
      <DecycleDashboardMain />
    </RootStyle>
  );
};

export default DecycleDashboardPage;
