import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomNoRowsOverlay from '../NoData';
import { UserItem } from './UserItem';
import { useFetchUsersQuery } from '../../services/UsersManagementService';
import { User } from '../../types/user';
import { StateWrapper } from '../wrappers/StateWrapper';
import { CommonListHead } from '../CommonListHead/CommonListHead';
import { TABLE_HEAD_LIST } from './constants';
import { useSorting } from '../../hooks/useSorting';

const LIMIT = 10;

export const UsersList: React.FC<any> = ({ handleDelete }) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);

  const { sortBy, orderBy, onClickSorting } = useSorting();

  const { data = {}, isLoading } = useFetchUsersQuery(
    { page, sortBy, orderBy },
    { refetchOnMountOrArgChange: true }
  );
  const { meta = {} } = data;

  const onEditUser = (id: number) => {
    return navigate(`/users/${id}/edit`);
  };

  const onDeleteUser = (id: number) => {
    handleDelete({ id });
    setIsOpen(false);
  };

  const handleOpen = (id: number) => {
    setItemId(id);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handlePageChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      setPage(newPage + 1);
    },
    []
  );

  return (
    <StateWrapper isLoading={isLoading}>
      <div style={{ width: '100%' }}>
        <TableContainer sx={{ bgcolor: 'background.paper' }} component={Paper}>
          <Table stickyHeader aria-label="simple table" sx={{ minWidth: '850px' }}>
            <CommonListHead
              headListData={TABLE_HEAD_LIST}
              sortBy={sortBy}
              orderBy={orderBy}
              align="center"
              onClick={onClickSorting}
            />
            <TableBody>
              {data.items ? (
                data.items.map((item: User) => (
                  <UserItem
                    key={item.id}
                    item={item}
                    onEdit={onEditUser}
                    onDelete={onDeleteUser}
                    isOpen={isOpen}
                    itemId={itemId}
                    onOpen={handleOpen}
                    onClose={handleClose}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                    <CustomNoRowsOverlay text="Users" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        {meta && (
          <TablePagination
            labelRowsPerPage={matches ? 'Rows per page:' : ''}
            sx={{ bgcolor: 'background.paper' }}
            rowsPerPageOptions={[]}
            component="div"
            count={meta.totalItems}
            rowsPerPage={LIMIT}
            page={meta.currentPage - 1}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </StateWrapper>
  );
};
