import { HeadCell } from '../../constants/defaultValues';

export const TABLE_HEAD_LIST: readonly HeadCell[] = [
  { id: 'company.name', label: 'Company' },
  { id: 'brand', label: 'Brand' },
  { id: 'brandGroup', label: 'Brand group' },
  { id: 'description', label: 'Description' },
  { id: 'updatedAt', label: 'Last updated' },
  { id: 'buttons', label: '' }
] as const;
