import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// material
import { spacing, SpacingProps } from '@mui/system';
import styled from 'styled-components/macro';
// hooks
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------
import {
  AppBar,
  Button as MuiButton,
  Container,
  Grid,
  Link as MuiLink,
  Toolbar
} from '@mui/material';
import { PATH_AUTH, PATH_DASHBOARD } from '../constants';

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  color: white;
`;

export default function Header() {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate(PATH_AUTH.SIGN_IN);
  };

  return (
    <React.Fragment>
      <AppBar position="relative" elevation={0} sx={{ background: 'rgb(46, 56, 77)' }}>
        <Toolbar>
          <Container>
            <Grid container alignItems="center">
              <MuiLink
                component={Link}
                to={PATH_DASHBOARD.ROOT}
                sx={{ '&:hover': { textDecoration: 'none' } }}
              >
                <Brand>Decycle Dashboard</Brand>
              </MuiLink>
              <Grid item xs />
              <Grid item>
                <Button ml={2} variant="contained" onClick={handleSignOut}>
                  Sign out
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
