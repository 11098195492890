import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { HeadCell, SortingOrder } from '../../constants/dashboard';
import styled from 'styled-components/macro';
import { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100
  }
}));

type CommonListHeadProps = {
  headListData: readonly HeadCell[];
  align?: 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined;
  sortBy?: SortingOrder;
  orderBy?: string;
  onClick?: (label: string) => void;
};

export const CommonListHead: React.FC<CommonListHeadProps> = ({
  headListData,
  align = 'left',
  sortBy,
  orderBy,
  onClick
}): JSX.Element => {
  const handleClick = (label: string): void => {
    if (typeof onClick === 'function') {
      onClick(label);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headListData.map((headCell) => (
          <StyledTableCell key={headCell.id} align={align}>
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={sortBy}
              onClick={(): void => handleClick(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
