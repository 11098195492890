import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { dashboardAPI } from '../services/DashboardsService';
import { dashboardAutocompleteAPI } from '../services/DashboardAutocompleteService';
import { usersManagementAPI } from '../services/UsersManagementService';
import { companyAPI } from '../services/CompanyService';

const rootReducer = combineReducers({
  [dashboardAPI.reducerPath]: dashboardAPI.reducer,
  [dashboardAutocompleteAPI.reducerPath]: dashboardAutocompleteAPI.reducer,
  [usersManagementAPI.reducerPath]: usersManagementAPI.reducer,
  [companyAPI.reducerPath]: companyAPI.reducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        dashboardAPI.middleware,
        dashboardAutocompleteAPI.middleware,
        usersManagementAPI.middleware,
        companyAPI.middleware
      ])
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
