import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Stack, TextField } from '@mui/material';
import useAuth from '../../hooks/useAuth';

const LoginConfirmVerified: React.FC = () => {
  const { loginConfirmVerified, email } = useAuth();

  const RegExp = /^\d+\.?\d*$/;

  const { errors, handleSubmit, touched, getFieldProps } = useFormik({
    initialValues: {
      activationCode: ''
    },
    validationSchema: Yup.object().shape({
      activationCode: Yup.string().required('Code is required').matches(RegExp, 'Is not valid')
    }),
    onSubmit: (values) => {
      loginConfirmVerified(email, values.activationCode);
    }
  });
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Stack direction="column" spacing={3} justifyContent="center">
        <TextField
          fullWidth
          label="Code"
          {...getFieldProps('activationCode')}
          error={Boolean(touched.activationCode && errors.activationCode)}
          helperText={touched.activationCode && errors.activationCode}
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          Verify
        </Button>
      </Stack>
    </form>
  );
};

export default LoginConfirmVerified;
