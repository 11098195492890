import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Box, Paper, Typography } from '@mui/material';
import ConfirmUpdateEmailComponent from '../../components/auth/ConfirmUpdateEmail';

const Wrapper = styled(Paper)`
  padding: 30px 40px;
`;

const ConfirmUpdateEmail: React.FC = () => {
  useEffect(() => {
    localStorage.removeItem('accessToken');
  }, []);
  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h3" paragraph>
          Please check the spelling of your new email:
        </Typography>
        <Box sx={{ mt: 5 }}>
          <ConfirmUpdateEmailComponent />
        </Box>
      </Wrapper>
    </React.Fragment>
  );
};

export default ConfirmUpdateEmail;
