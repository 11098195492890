import { useCallback, useState } from 'react';
import { SortingOrder } from '../constants/defaultValues';

type SortingProps = {
  sortBy: SortingOrder;
  orderBy: string;
  onClickSorting: (label: string) => void;
};

export const useSorting = (): SortingProps => {
  const [sortBy, setSortBy] = useState<SortingOrder>(SortingOrder.DESC);
  const [orderBy, setOrderBy] = useState<string>('');

  const onClickSorting = useCallback((label: string): void => {
    setOrderBy(label);
    setSortBy((prevValue) =>
      prevValue === SortingOrder.DESC ? SortingOrder.ASC : SortingOrder.DESC
    );
  }, []);

  return {
    sortBy,
    orderBy,
    onClickSorting
  };
};
