import React from 'react';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { Button, TextField as MuiTextField } from '@mui/material';
import { spacing } from '@mui/system';
import useAuth from '../../hooks/useAuth';
import { forgotPasswordRules } from './rules';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const DEFAULT_VALUES = {
  email: '',
  submit: false
};

type ForgotPasswordValues = {
  email: string;
  submit: boolean;
};

const ForgotPassword: React.FC = () => {
  const { forgotPassword } = useAuth();

  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    useFormik<ForgotPasswordValues>({
      initialValues: DEFAULT_VALUES,
      validationSchema: forgotPasswordRules,
      validateOnChange: true,
      onSubmit: async (values) => {
        forgotPassword(values.email);
      }
    });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        type="email"
        name="email"
        label="Email Address"
        value={values.email}
        error={Boolean(touched.email && errors.email)}
        fullWidth
        helperText={touched.email && errors.email}
        onBlur={handleBlur}
        onChange={handleChange}
        my={3}
      />
      <Button type="submit" fullWidth variant="contained" color="primary">
        Forgot password
      </Button>
    </form>
  );
};

export default ForgotPassword;
