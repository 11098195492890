import React from 'react';
import { Box, CircularProgress } from '@mui/material';

type StateWrapperProps = {
  children: React.ReactNode;
  isLoading: boolean;
};

export const StateWrapper: React.FC<StateWrapperProps> = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
        <CircularProgress size={50} />
      </Box>
    );
  }
  return <>{children}</>;
};
