import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { resetPasswordRules } from './rules';
import { PasswordInput } from '../PasswordInput';

const DEFAULT_VALUES = {
  password: '',
  submit: false
};

type ResetPasswordValues = {
  password: string;
  submit: boolean;
};

const ResetPassword: React.FC = () => {
  const { resetPassword } = useAuth();
  const [searchParams] = useSearchParams();
  const registrationCode = searchParams.get('code') || '';

  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    useFormik<ResetPasswordValues>({
      initialValues: DEFAULT_VALUES,
      validationSchema: resetPasswordRules,
      validateOnChange: true,
      onSubmit: async (values) => {
        resetPassword(values.password, registrationCode);
      }
    });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <PasswordInput
        value={values.password}
        name="password"
        label="Password"
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(touched.password && errors.password)}
        helperText={touched.password && errors.password}
        my={3}
      />
      <Button type="submit" fullWidth variant="contained" color="primary">
        Reset password
      </Button>
    </form>
  );
};

export default ResetPassword;
