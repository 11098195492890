import * as Yup from 'yup';
import { ROLES } from '../../../constants/defaultValues';

const NAME_REGEX = /^[-a-zA-Z\u00C0-\u00FF'-]+(\s+[-a-zA-Z\u00C0-\u00FF'-]+)*$/;

export const rules = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required')
    .matches(NAME_REGEX, 'Incorrect first name form'),
  lastName: Yup.string()
    .required('Last name is required')
    .matches(NAME_REGEX, 'Incorrect last name form'),
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  role: Yup.string()
    .required('Role is required')
    .oneOf(ROLES, 'Invalid role')
    .typeError('Please, select role from list')
});
