export enum UserRoles {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER'
}

export const ROLES = [UserRoles.SUPERADMIN, UserRoles.ADMIN, UserRoles.USER];

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export type HeadCell = {
  id: string;
  label?: string;
};
