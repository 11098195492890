import React from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Stack, TextField } from '@mui/material';
import useAuth from '../../hooks/useAuth';

const ConfirmVerified: React.FC = () => {
  const { confirmVerified } = useAuth();
  const { search } = useLocation();
  const registrationCode = new URLSearchParams(search).get('code');
  const RegExp = /^\d+\.?\d*$/;
  return (
    <Formik
      initialValues={{
        activationCode: ''
      }}
      validationSchema={Yup.object().shape({
        activationCode: Yup.string().required('Code is required').matches(RegExp, 'Is not valid')
      })}
      onSubmit={(values) => {
        confirmVerified(registrationCode, values.activationCode);
      }}
    >
      {({ errors, handleSubmit, touched, getFieldProps }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Stack direction="column" spacing={3} justifyContent="center">
            <TextField
              fullWidth
              label="Code"
              {...getFieldProps('activationCode')}
              error={Boolean(touched.activationCode && errors.activationCode)}
              helperText={touched.activationCode && errors.activationCode}
            />
            <Button type="submit" fullWidth variant="contained" color="primary">
              Verify
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default ConfirmVerified;
