import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Card,
  Autocomplete,
  Typography,
  TextFieldProps,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import styled from 'styled-components/macro';
import {
  useFetchBrandGroupsQuery,
  useFetchBrandsQuery,
  useFetchTerritoriesQuery,
  useFetchLocationsQuery,
  useFetchMaterialGroupsQuery,
  useFetchMaterialsQuery,
  useFetchStagesQuery,
  useFetchTypesQuery
} from '../../../services/DashboardAutocompleteService';
import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatPickerValue, isValidDate } from '../../../utils/dateHelpers';
import { Recommendations } from './Recommendations';
import { getIdsFromArray, isNotEmpty } from '../../../utils/helpers';
import { rules } from './rules';
import {
  BrandItem,
  DashboardProps,
  FormValues,
  LocationItem,
  MaterialGroupItem,
  MaterialListItem,
  OptionItem,
  TerritoryItem
} from '../../../types/dashboardForm';
import { StateWrapper } from '../../wrappers/StateWrapper';
import { NumericInput } from '../../NumericInput';
import { CountingTextField } from '../../CountingTextField';
import { useSnackbar } from 'notistack';
import { PictureCard } from './PictureCard';
import { useFetchCompaniesQuery } from '../../../services/CompanyService';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

const DatePickerTextField = styled(TextField)(({ theme }) => ({
  width: '80%',
  [theme.breakpoints.down('lg')]: {
    width: '70%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '55%'
  }
}));

type DashboardFormProps = {
  dashboardId: string;
  data?: DashboardProps;
  isLoading?: boolean;
  handleRequest: (values: any) => void;
  isSuccessRequest: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  isLoadingRequest: boolean;
  route: string;
};

const LIMIT = 100;
const DEFAULT_VERSION = 'Version 1';

const MAX_FILE_SIZE = 10485760;
const ERROR_IMAGE_UPDATE = 'Please add a picture up to 10MB';

export const DashboardForm: React.FC<DashboardFormProps> = ({
  dashboardId = '',
  data = {},
  isLoading,
  handleRequest,
  isSuccessRequest,
  error,
  isLoadingRequest,
  route
}) => {
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [cropper, setCropper] = useState<any>();
  const [pictureUrl, setPictureUrl] = useState<string | null>(null);
  const [isCheckedTbc, setIsCheckedTbc] = useState<boolean>(false);

  const [selectedBrandGroup, setSelectedBrandGroup] = useState<string>('');
  const [selectedTerritoryIds, setSelectedTerritoryIds] = useState<number[]>([]);
  const [selectedLocationsIds, setSelectedLocationsIds] = useState<number[]>([]);
  const [selectedMaterialGroupIds, setSelectedMaterialGroupIds] = useState<number[]>([]);
  const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([]);

  const { data: companies = [] } = useFetchCompaniesQuery({});
  const { data: stages = [] } = useFetchStagesQuery({});
  const { data: types = [] } = useFetchTypesQuery({});

  const { data: brandGroups = [], isLoading: isLoadingBrandGroups } = useFetchBrandGroupsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const { data: brands = [] } = useFetchBrandsQuery(selectedBrandGroup, {
    refetchOnMountOrArgChange: true,
    skip: !selectedBrandGroup
  });
  const { data: territories = [], isLoading: isLoadingTerritories } = useFetchTerritoriesQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const { data: locations = [] } = useFetchLocationsQuery(
    { limit: LIMIT },
    {
      refetchOnMountOrArgChange: true
    }
  );
  const { data: materialGroups = [], isLoading: isLoadingMaterialGroups } =
    useFetchMaterialGroupsQuery({}, { refetchOnMountOrArgChange: true });

  const { data: materials = [] } = useFetchMaterialsQuery(`${selectedMaterialGroupIds}`, {
    refetchOnMountOrArgChange: true,
    skip: selectedMaterialGroupIds.length === 0
  });

  const firstSettingRequestParams = useCallback(() => {
    if (isNotEmpty(data) && data.brandGroup) {
      setSelectedBrandGroup(data?.brandGroup);
      setSelectedMaterialIds(getIdsFromArray(data.materialLists));
      setSelectedMaterialGroupIds(getIdsFromArray(data.materialGroups));
      setSelectedTerritoryIds(getIdsFromArray(data.distributionTerritories));
      setSelectedLocationsIds(getIdsFromArray(data.productionLocations));
    }

    if (data.tbc) {
      setIsCheckedTbc(data.tbc);
    }

    if (data.brandPicture) {
      setPictureUrl(data.brandPicture);
    }
  }, [data]);

  useEffect(() => {
    firstSettingRequestParams();
  }, [firstSettingRequestParams]);

  useEffect(() => {
    if (isSuccessRequest) {
      enqueueSnackbar('Changes saved', {
        variant: 'success'
      });

      navigate(route);
    }

    if (error) {
      const message = (error as { data: { message: string } })?.data?.message || ERROR_IMAGE_UPDATE;

      enqueueSnackbar(message, {
        variant: 'error'
      });
    }
  }, [enqueueSnackbar, error, isSuccessRequest, navigate, route]);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      company: data.company || null,
      brandGroup: data.brandGroup || '',
      brand: data.brand || '',
      launchDate: data.launchDate || '',
      tbc: data.tbc || false,
      version: data.version || DEFAULT_VERSION,
      SKU: data.SKU || '',
      stage: data.stage || null,
      type: data.type || null,
      designOrigin: data.designOrigin || '',
      sustainabilityOptimisation: data.sustainabilityOptimisation || '',
      componentCount: data?.componentCount?.toString() || '',
      materialCount: data?.materialCount?.toString() || '',
      materialLists: data.materialLists || [],
      materialGroups: data.materialGroups || [],
      productionLocations: data.productionLocations || [],
      distributionTerritories: data.distributionTerritories || [],
      estimatedProductQuantity: data?.estimatedProductQuantity?.toString() || '',
      packedDimensions: data.packedDimensions || '',
      materialStrategy: data.materialStrategy || '',
      materialStrategyScore: data?.materialStrategyScore?.toString() || '',
      productConstruct: data.productConstruct || '',
      productConstructScore: data?.productConstructScore?.toString() || '',
      recylability: data.recylability || '',
      recylabilityScore: data?.recylabilityScore?.toString() || '',
      lifetime: data.lifetime || '',
      lifetimeScore: data?.lifetimeScore?.toString() || '',
      logistics: data.logistics || '',
      logisticsScore: data?.logisticsScore?.toString() || '',
      notes: data.notes || '',
      sustainabilityScore: data?.sustainabilityScore?.toString() || '',
      brandPicture: data.brandPicture || ''
    },
    validationSchema: rules,
    onSubmit: async (values, { setSubmitting }) => {
      const form = new FormData();
      form.append('companyId', String(values.company?.id));
      form.append('brandGroup', values.brandGroup);
      form.append('brand', values.brand);
      form.append('launchDate', values.launchDate?.toLocaleString() || '');
      form.append('tbc', String(values.tbc));
      form.append('version', values.version);
      form.append('SKU', values.SKU);
      form.append('stageId', String(values.stage?.id));
      form.append('typeId', String(values.type?.id));
      form.append('designOrigin', values.designOrigin);
      form.append('sustainabilityOptimisation', values.sustainabilityOptimisation);
      form.append('componentCount', values.componentCount);
      form.append('materialCount', values.materialCount);
      form.append('materialLists', `[${selectedMaterialIds}]`);
      form.append('materialGroups', `[${selectedMaterialGroupIds}]`);
      form.append('estimatedProductQuantity', values.estimatedProductQuantity);
      form.append('productionLocations', `[${selectedLocationsIds}]`);
      form.append('distributionTerritories', `[${selectedTerritoryIds}]`);
      form.append('packedDimensions', String(values.packedDimensions));
      form.append('materialStrategy', values.materialStrategy);
      form.append('materialStrategyScore', values.materialStrategyScore);
      form.append('productConstruct', values.productConstruct);
      form.append('productConstructScore', values.productConstructScore);
      form.append('recylability', values.recylability);
      form.append('recylabilityScore', values.recylabilityScore);
      form.append('lifetime', values.lifetime);
      form.append('lifetimeScore', values.lifetimeScore);
      form.append('logistics', values.logistics);
      form.append('logisticsScore', values.logisticsScore);
      form.append('notes', values.notes);
      form.append('sustainabilityScore', values.sustainabilityScore);
      form.append('brandPicture', values.brandPicture);
      isEdit && form.append('id', dashboardId);

      handleRequest(form);
      setSubmitting(false);
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values, setFieldError } =
    formik;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    if (file.size > MAX_FILE_SIZE) {
      enqueueSnackbar(ERROR_IMAGE_UPDATE, { variant: 'error' });
      return;
    }

    setPictureUrl(URL.createObjectURL(file));
    setFieldValue('brandPicture', file);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      urlToFile(cropper.getCroppedCanvas().toDataURL(), 'test.png', 'image/png').then(function (
        file
      ) {
        setPictureUrl(cropper.getCroppedCanvas().toDataURL());
        setFieldValue('brandPicture', file);
      });
    }
  };

  function urlToFile(url: string, filename: string, mimeType: string) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  const onChangeBrandGroups = useCallback(
    (event: SyntheticEvent<Element, Event>, newValue: string | null) => {
      setSelectedBrandGroup(newValue || '');
      setFieldValue('brandGroup', newValue || '');
      setFieldValue('brand', '');
    },
    [setFieldValue]
  );

  const onChangeBrand = useCallback(
    (event: any, newValue: string | null) => {
      setFieldValue('brand', newValue || '');
    },
    [setFieldValue]
  );

  const brandGroupsOptions = brandGroups.map((brandGroup: OptionItem) => brandGroup.name) || [];
  const brandsOptions = brands?.map((brand: BrandItem) => brand.tittle) || [];
  const materialsOptions = materials?.items || [];
  const locationsOptions = locations?.items || [];

  const isLoadingAll =
    isLoading || isLoadingBrandGroups || isLoadingMaterialGroups || isLoadingTerritories;

  return (
    <StateWrapper isLoading={isLoadingAll}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3} paddingTop="20px">
            <Grid item xs={12} sm={12} md={4} sx={{ padding: 4 }}>
              <PictureCard
                pictureUrl={pictureUrl}
                handleChange={handleImageChange}
                getCropData={getCropData}
                setCropper={setCropper}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} sx={{ padding: 4 }}>
              <Card sx={{ p: 3, bgcolor: 'background.paper' }}>
                <Stack spacing={3}>
                  <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                    Details
                  </Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Autocomplete
                      autoComplete={false}
                      fullWidth
                      options={companies}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={values.company}
                      getOptionLabel={(option: OptionItem): string => option.name}
                      onChange={(event: SyntheticEvent, newValue) => {
                        setFieldValue('company', newValue);
                      }}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Company"
                          error={Boolean(touched.company && errors.company)}
                          helperText={touched.company && errors.company}
                        />
                      )}
                    />
                    <Autocomplete
                      autoComplete={false}
                      fullWidth
                      options={brandGroupsOptions}
                      isOptionEqualToValue={(option, value) => option === value}
                      value={values.brandGroup}
                      getOptionLabel={(option: string): string => option}
                      onChange={onChangeBrandGroups}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Brand group"
                          error={Boolean(touched.brandGroup && errors.brandGroup)}
                          helperText={touched.brandGroup && errors.brandGroup}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Autocomplete
                      autoComplete={false}
                      disabled={!values.brandGroup}
                      fullWidth
                      value={values.brand}
                      options={brandsOptions}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option: string): string => option}
                      onChange={onChangeBrand}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Brand"
                          error={Boolean(touched.brand && errors.brand)}
                          helperText={touched.brand && errors.brand}
                        />
                      )}
                    />
                    <TextField
                      fullWidth
                      label="SKU"
                      {...getFieldProps('SKU')}
                      error={Boolean(touched.SKU && errors.SKU)}
                      helperText={touched.SKU && errors.SKU}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Autocomplete
                      autoComplete={false}
                      fullWidth
                      options={stages}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={values.stage}
                      getOptionLabel={(option: OptionItem): string => option.name}
                      onChange={(event: SyntheticEvent, newValue) => {
                        setFieldValue('stage', newValue);
                      }}
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Stages of production"
                          error={Boolean(touched.stage && errors.stage)}
                          helperText={touched.stage && errors.stage}
                        />
                      )}
                    />
                    <Autocomplete
                      autoComplete={false}
                      fullWidth
                      value={values.type}
                      options={types}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(option: OptionItem): string => option.name}
                      onChange={(event: SyntheticEvent, newValue) => {
                        setFieldValue('type', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Type"
                          error={Boolean(touched.type && errors.type)}
                          helperText={touched.type && errors.type}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Version"
                      {...getFieldProps('version')}
                      error={Boolean(touched.version && errors.version)}
                      helperText={touched.version && errors.version}
                    />
                    <Grid
                      item
                      xs={12}
                      md={12}
                      direction="row"
                      wrap="nowrap"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Launch Date"
                          inputFormat="MM/dd/yyyy"
                          mask="__/__/____"
                          views={['day']}
                          disabled={isCheckedTbc}
                          value={values.launchDate}
                          onChange={(newValue) => {
                            if (
                              newValue !== null &&
                              new Date(newValue as string).toString() === 'Invalid Date'
                            ) {
                              setFieldError('launchDate', 'Date is invalid');
                            }
                            if (isValidDate(newValue)) {
                              setFieldValue('launchDate', formatPickerValue(newValue));
                            }
                          }}
                          renderInput={(params: TextFieldProps): JSX.Element => (
                            <DatePickerTextField
                              {...params}
                              {...getFieldProps('launchDate')}
                              error={Boolean(
                                !isCheckedTbc && touched.launchDate && errors.launchDate
                              )}
                              helperText={!isCheckedTbc && touched.launchDate && errors.launchDate}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <FormControlLabel
                        label="tbc"
                        sx={{
                          marginRight: '0',
                          marginLeft: '0',
                          marginTop: '5px'
                        }}
                        control={
                          <Checkbox
                            checked={isCheckedTbc}
                            onChange={() => {
                              setIsCheckedTbc((prevState) => !prevState);
                              setFieldValue('tbc', !isCheckedTbc);
                              setFieldValue('launchDate', null);
                            }}
                            name="tbc"
                          />
                        }
                      />
                    </Grid>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} sx={{ padding: 4 }}>
              <Card sx={{ p: 3, bgcolor: 'background.paper' }}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Design origin"
                      {...getFieldProps('designOrigin')}
                      error={Boolean(touched.designOrigin && errors.designOrigin)}
                      helperText={touched.designOrigin && errors.designOrigin}
                    />
                    <TextField
                      fullWidth
                      label="Sustainability Optimisation"
                      {...getFieldProps('sustainabilityOptimisation')}
                      error={Boolean(
                        touched.sustainabilityOptimisation && errors.sustainabilityOptimisation
                      )}
                      helperText={
                        touched.sustainabilityOptimisation && errors.sustainabilityOptimisation
                      }
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <NumericInput
                      label="Component count"
                      name="componentCount"
                      min={0}
                      value={values.componentCount}
                      setFieldValue={setFieldValue}
                      error={Boolean(touched.componentCount && errors.componentCount)}
                      helperText={touched.componentCount && errors.componentCount}
                    />
                    <NumericInput
                      label="Material count"
                      name="materialCount"
                      min={0}
                      value={values.materialCount}
                      setFieldValue={setFieldValue}
                      error={Boolean(touched.materialCount && errors.materialCount)}
                      helperText={touched.materialCount && errors.materialCount}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Autocomplete
                      multiple
                      autoComplete={false}
                      fullWidth
                      includeInputInList
                      options={materialGroups}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                      value={values.materialGroups}
                      getOptionLabel={(option: MaterialGroupItem): string => option.groupName}
                      onChange={(event: SyntheticEvent, newValue) => {
                        setFieldValue('materialGroups', newValue);
                        setSelectedMaterialGroupIds(getIdsFromArray(newValue));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Material group"
                          error={Boolean(touched.materialGroups && errors.materialGroups)}
                          helperText={touched.materialGroups && errors.materialGroups}
                        />
                      )}
                    />
                    <Autocomplete
                      multiple
                      autoComplete={false}
                      disabled={!values.materialGroups.length}
                      fullWidth
                      options={materialsOptions}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={values.materialLists}
                      getOptionLabel={(option: MaterialListItem): string => option.name}
                      onChange={(event, newValue) => {
                        setFieldValue('materialLists', newValue);
                        setSelectedMaterialIds(getIdsFromArray(newValue));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Material list"
                          value={values.materialLists}
                          error={Boolean(touched.materialLists && errors.materialLists)}
                          helperText={touched.materialLists && errors.materialLists}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <NumericInput
                      label="Estimated product quantity"
                      name="estimatedProductQuantity"
                      min={0}
                      value={values.estimatedProductQuantity}
                      setFieldValue={setFieldValue}
                      error={Boolean(
                        touched.estimatedProductQuantity && errors.estimatedProductQuantity
                      )}
                      helperText={
                        touched.estimatedProductQuantity && errors.estimatedProductQuantity
                      }
                    />
                    <Autocomplete
                      multiple
                      autoComplete={false}
                      disabled={!values.distributionTerritories}
                      fullWidth
                      options={locationsOptions}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={values.productionLocations}
                      ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                      getOptionLabel={(option: LocationItem): string => option.countryName}
                      onChange={(event, newValue) => {
                        setFieldValue('productionLocations', newValue);
                        setSelectedLocationsIds(getIdsFromArray(newValue));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Production location"
                          error={Boolean(touched.productionLocations && errors.productionLocations)}
                          helperText={touched.productionLocations && errors.productionLocations}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Autocomplete
                      multiple
                      autoComplete={false}
                      fullWidth
                      options={territories}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={values.distributionTerritories}
                      ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                      getOptionLabel={(option: TerritoryItem): string => option.continentName}
                      onChange={(event, newValue) => {
                        setFieldValue('distributionTerritories', newValue);
                        setSelectedTerritoryIds(getIdsFromArray(newValue));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Distribution territories"
                          error={Boolean(
                            touched.distributionTerritories && errors.distributionTerritories
                          )}
                          helperText={
                            touched.distributionTerritories && errors.distributionTerritories
                          }
                        />
                      )}
                    />
                    <TextField
                      fullWidth
                      label="Packed dimensions"
                      {...getFieldProps('packedDimensions')}
                      error={Boolean(touched.packedDimensions && errors.packedDimensions)}
                      helperText={touched.packedDimensions && errors.packedDimensions}
                    />
                  </Stack>
                  <Stack spacing={{ xs: 3, sm: 2 }}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      pr={{ xs: 0, md: 1 }}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <NumericInput
                        label="General sustainability score"
                        name="sustainabilityScore"
                        min={0}
                        value={values.sustainabilityScore}
                        setFieldValue={setFieldValue}
                        error={Boolean(touched.sustainabilityScore && errors.sustainabilityScore)}
                        helperText={touched.sustainabilityScore && errors.sustainabilityScore}
                      />
                    </Grid>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} sx={{ padding: 4 }}>
              <Card sx={{ p: 3, bgcolor: 'background.paper' }}>
                <Stack spacing={3}>
                  <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                    Optimisation
                  </Typography>
                  <Stack direction={{ xs: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                    <CountingTextField
                      minRows={3}
                      maxRows={5}
                      limit={250}
                      currentLength={values.materialStrategy.length}
                      label="Material strategy"
                      {...getFieldProps('materialStrategy')}
                      error={Boolean(touched.materialStrategy && errors.materialStrategy)}
                      helperText={touched.materialStrategy && errors.materialStrategy}
                    />
                    <Grid item xs={12} md={4}>
                      <NumericInput
                        label="Material strategy score"
                        name="materialStrategyScore"
                        min={0}
                        value={values.materialStrategyScore}
                        setFieldValue={setFieldValue}
                        error={Boolean(
                          touched.materialStrategyScore && errors.materialStrategyScore
                        )}
                        helperText={touched.materialStrategyScore && errors.materialStrategyScore}
                      />
                    </Grid>
                  </Stack>
                  <Stack direction={{ xs: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                    <CountingTextField
                      minRows={3}
                      maxRows={5}
                      limit={250}
                      currentLength={values.productConstruct.length}
                      label="Product construct"
                      {...getFieldProps('productConstruct')}
                      error={Boolean(touched.productConstruct && errors.productConstruct)}
                      helperText={touched.productConstruct && errors.productConstruct}
                    />
                    <Grid item xs={12} md={4}>
                      <NumericInput
                        label="Product construct score"
                        name="productConstructScore"
                        min={0}
                        value={values.productConstructScore}
                        setFieldValue={setFieldValue}
                        error={Boolean(
                          touched.productConstructScore && errors.productConstructScore
                        )}
                        helperText={touched.productConstructScore && errors.productConstructScore}
                      />
                    </Grid>
                  </Stack>
                  <Stack direction={{ xs: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                    <CountingTextField
                      minRows={3}
                      maxRows={5}
                      limit={250}
                      currentLength={values.recylability.length}
                      label="Recyclability"
                      {...getFieldProps('recylability')}
                      error={Boolean(touched.recylability && errors.recylability)}
                      helperText={touched.recylability && errors.recylability}
                    />
                    <Grid item xs={12} md={4}>
                      <NumericInput
                        label="Recyclability score"
                        name="recylabilityScore"
                        min={0}
                        value={values.recylabilityScore}
                        setFieldValue={setFieldValue}
                        error={Boolean(touched.recylabilityScore && errors.recylabilityScore)}
                        helperText={touched.recylabilityScore && errors.recylabilityScore}
                      />
                    </Grid>
                  </Stack>
                  <Stack direction={{ xs: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                    <CountingTextField
                      minRows={3}
                      maxRows={5}
                      limit={250}
                      currentLength={values.lifetime.length}
                      label="Lifetime"
                      {...getFieldProps('lifetime')}
                      error={Boolean(touched.lifetime && errors.lifetime)}
                      helperText={touched.lifetime && errors.lifetime}
                    />
                    <Grid item xs={12} md={4}>
                      <NumericInput
                        label="Lifetime score"
                        name="lifetimeScore"
                        min={0}
                        value={values.lifetimeScore}
                        setFieldValue={setFieldValue}
                        error={Boolean(touched.lifetimeScore && errors.lifetimeScore)}
                        helperText={touched.lifetimeScore && errors.lifetimeScore}
                      />
                    </Grid>
                  </Stack>
                  <Stack direction={{ xs: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                    <CountingTextField
                      minRows={3}
                      maxRows={5}
                      limit={250}
                      currentLength={values.logistics.length}
                      label="Logistics"
                      {...getFieldProps('logistics')}
                      error={Boolean(touched.logistics && errors.logistics)}
                      helperText={touched.logistics && errors.logistics}
                    />
                    <Grid item xs={12} md={4}>
                      <NumericInput
                        label="Logistics score"
                        name="logisticsScore"
                        min={0}
                        value={values.logisticsScore}
                        setFieldValue={setFieldValue}
                        error={Boolean(touched.logisticsScore && errors.logisticsScore)}
                        helperText={touched.logisticsScore && errors.logisticsScore}
                      />
                    </Grid>
                  </Stack>
                  <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                    Notes
                  </Typography>
                  <Stack spacing={{ xs: 3, sm: 2 }}>
                    <CountingTextField
                      minRows={5}
                      maxRows={10}
                      limit={500}
                      currentLength={values.notes.length}
                      label="Notes"
                      {...getFieldProps('notes')}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                    />
                  </Stack>
                  {isEdit && (
                    <Recommendations
                      dashboardId={dashboardId}
                      recommendations={data.dashboardRecommendations || []}
                    />
                  )}
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton
                      type="button"
                      variant="contained"
                      color="primary"
                      value="save"
                      loading={isLoadingRequest}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </StateWrapper>
  );
};
