// Themes
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO'
};

const ROOTS_AUTH = '/auth';
const ROOTS_PAGE = '/';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_USERS = '/users';

export const PATH_AUTH = {
  ROOT: ROOTS_AUTH,
  SIGN_IN: `${ROOTS_AUTH}/sign-in`,
  SIGN_UP: `${ROOTS_AUTH}/sign-up`,
  FORGOT_PASSWORD: `${ROOTS_AUTH}/forgot-password`,
  RESET_PASSWORD: `${ROOTS_AUTH}/reset-password`,
  CONFIRM_CODE: `${ROOTS_AUTH}/send-2fa/code`
};

export const PATH_DASHBOARD = {
  ROOT: ROOTS_PAGE,
  ADD: `${ROOTS_DASHBOARD}/add`,
  LIST: `${ROOTS_DASHBOARD}/list`,
  EDIT: `${ROOTS_DASHBOARD}/:id/edit`,
  VIEW_DASHBOARD: 'https://dd.firs.org.ua/'
};

export const PATH_USERS = {
  INVITE: `${ROOTS_USERS}/invite`,
  LIST: `${ROOTS_USERS}/list`,
  EDIT: `${ROOTS_USERS}/:id/edit`
};

export const PATH_PAGES = {
  ROOT: ROOTS_PAGE,
  PAGE_404: '/404',
  PAGE_500: '/500'
};
