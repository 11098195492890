import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { PATH_PAGES } from '../../constants';

import useAuth from '../../hooks/useAuth';

interface GuestGuardType {
  children: ReactNode;
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <Navigate to={PATH_PAGES.ROOT} />;
  }

  return <>{children}</>;
}

export default GuestGuard;
