import React from 'react';
import { IconButton, InputAdornment, TextField as MuiTextField } from '@mui/material';
import { Eye, EyeOff } from 'react-feather';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import { usePasswordField } from '../hooks/usePasswordField';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

type PasswordInputProps = {
  value: string;
  error: boolean;
  helperText?: string | false;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: string;
  my: number;
};

enum InputTypes {
  PASSWORD = 'password',
  TEXT = 'text'
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  value,
  error,
  helperText,
  onBlur,
  onChange,
  name,
  label,
  my
}) => {
  const { isShowPassword, togglePasswordVisibility } = usePasswordField();

  return (
    <>
      {/* fake input for disabling autocomplete */}
      <input
        type="password"
        name="fake-password"
        autoComplete="new-password"
        style={{ display: 'none' }}
      />
      <TextField
        type={isShowPassword ? InputTypes.TEXT : InputTypes.PASSWORD}
        name={name}
        label={label}
        value={value}
        error={error}
        fullWidth
        helperText={helperText}
        onBlur={onBlur}
        onChange={onChange}
        my={my}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {isShowPassword ? <Eye /> : <EyeOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </>
  );
};
