import React, { useCallback, useLayoutEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { PATH_PAGES } from '../../constants';

const ConfirmVerified: React.FC = () => {
  const { confirmUpdateEmail, checkRegistrationCode } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email')?.replace(' ', '+') || '';
  const registrationCode = searchParams.get('code') || '';

  useLayoutEffect(() => {
    checkRegistrationCode(registrationCode);
  }, [checkRegistrationCode, registrationCode]);

  const onConfirmCLick = useCallback(() => {
    confirmUpdateEmail(email, registrationCode);
  }, [confirmUpdateEmail, email, registrationCode]);

  const onCancelCLick = useCallback(() => {
    navigate(PATH_PAGES.ROOT);
  }, [navigate]);

  return (
    <Box justifyContent="center">
      <Typography display="flex" justifyContent="center" variant="h4">
        {email}
      </Typography>
      <Typography
        display="flex"
        justifyContent="center"
        sx={{ color: 'text.secondary', mt: 5, mb: 8 }}
      >
        Are you sure you want to change your email to a new one?
      </Typography>
      <Stack direction="row" spacing={30} justifyContent="space-between">
        <Button onClick={onCancelCLick} fullWidth color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirmCLick} fullWidth variant="contained" color="primary">
          Confirm
        </Button>
      </Stack>
    </Box>
  );
};

export default ConfirmVerified;
