export const API_ROUTES = {
  AUTH: {
    PROFILE: '/auth/profile',
    LOGIN: '/auth/login',
    SIGN_UP: '/auth/register',
    INVITE_REGISTER: '/auth/register/invitation',
    CHECK_CODE: '/auth/check-registration-code',
    SEND_CODE: '/auth/2fa-code',
    CONFIRM_CODE: '/auth/2fa-confirm',
    LOGIN_CONFIRM: '/auth/2fa-confirm-login',
    FORGOT_PASSWORD: '/auth/send-reset-password-email',
    RESET_PASSWORD: '/auth/reset-password',
    CONFIRM_UPDATE_EMAIL: '/user/accept-update-email'
  },
  DASHBOARD: {
    ROOT: '/dashboard',
    DELETE: '/dashboard/versions'
  },
  IMAGE: '/dashboard/image',
  RECOMMENDATION: '/dashboard/recommendation',
  STAGE: '/stage',
  TYPE: '/type',
  BRANDS: {
    ROOT: '/brand',
    GROUP: '/brand/group'
  },
  TERRITORIES: {
    ROOT: '/location/territory',
    LOCATIONS: '/location/byTerritory'
  },
  MATERIALS: {
    GROUPS: '/material/group',
    LISTS: '/material/byGroup'
  },
  USERS: {
    ROOT: '/user',
    INVITE: '/user/invite'
  },
  COMPANY: {
    ROOT: '/company'
  }
};
